@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800,400&display=swap');

.crisp-client #crisp-chatbox .cc-1qth.cc-1s3d{
  background: rgb(19,154,67) !important;
  background: linear-gradient(-90deg, rgba(19,154,67,1) 0%, rgba(19,123,154,1) 100%) !important;
  outline: solid 3px white !important;
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
